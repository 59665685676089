import { render, staticRenderFns } from "./UserTeams.vue?vue&type=template&id=428a3689&scoped=true"
import script from "./UserTeams.vue?vue&type=script&lang=ts"
export * from "./UserTeams.vue?vue&type=script&lang=ts"
import style0 from "./UserTeams.vue?vue&type=style&index=0&id=428a3689&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.88.2_@swc+core@1.3.82___lodash@4.17.21_prettier_o5qnydi3pl2qxxbsmsrcibgdpu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428a3689",
  null
  
)

export default component.exports