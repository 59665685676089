import { render, staticRenderFns } from "./ListOfRootItems.vue?vue&type=template&id=70ebc46c&scoped=true"
import script from "./ListOfRootItems.vue?vue&type=script&lang=ts"
export * from "./ListOfRootItems.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.88.2_@swc+core@1.3.82___lodash@4.17.21_prettier_o5qnydi3pl2qxxbsmsrcibgdpu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ebc46c",
  null
  
)

export default component.exports